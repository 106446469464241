import React from "react"
import {
  CollectionAssetsPreview,
  CollectionAssetsPreviewProps,
} from "@/components/collections/CollectionAssetsPreview.react"
import { IS_SERVER } from "@/constants/environment"
import { Block } from "@/design-system/Block"
import { Tooltip, TooltipProps } from "@/design-system/Tooltip"

type CollectionAssetsPreviewTooltipProps = {
  children: React.ReactNode
  width?: string
  disabled?: boolean
} & CollectionAssetsPreviewProps &
  Pick<TooltipProps, "placement" | "followCursor">

export const CollectionAssetsPreviewTooltip = ({
  children,
  placement = "auto",
  disabled = false,
  followCursor = false,
  width = "100%",
  ...collectionAssetsPreviewProps
}: CollectionAssetsPreviewTooltipProps) => {
  return (
    <Tooltip
      animation="fade"
      appendTo={IS_SERVER ? undefined : document.body}
      arrow={false}
      className="rounded-xl"
      content={<CollectionAssetsPreview {...collectionAssetsPreviewProps} />}
      contentPadding="2px"
      disabled={disabled}
      followCursor={followCursor}
      interactive={!followCursor}
      maxWidth="none"
      placement={placement}
      popperOptions={{
        modifiers: [],
      }}
    >
      <Block width={width}>{children}</Block>
    </Tooltip>
  )
}
