import React, { Suspense, useEffect, useState } from "react"
import { Flex, Text } from "@opensea/ui-kit"
import { ErrorBoundary } from "@sentry/nextjs"
import { useLazyLoadQuery } from "react-relay"
import { AssetMedia } from "@/components/assets/AssetMedia"
import { Link } from "@/components/common/Link"
import { PreviewList } from "@/components/common/PreviewList.react"
import { useTranslate } from "@/hooks/useTranslate"
import {
  CollectionAssetsPreviewQuery,
  TraitInputType,
  TraitRangeType,
} from "@/lib/graphql/__generated__/CollectionAssetsPreviewQuery.graphql"
import { getNodes, graphql } from "@/lib/graphql/graphql"
import { getItemUrl } from "@/lib/helpers/item"
import { captureNoncriticalError } from "@/lib/sentry"

export type CollectionAssetsPreviewProps = {
  slug: string
  imageSize?: number
  stringTraits?: TraitInputType[]
  numericTraits?: TraitRangeType[]
  count?: number
  delay?: number
}

export const CollectionAssetsPreview = (
  props: CollectionAssetsPreviewProps,
) => {
  const count = props.count ?? 3
  const imageSize = props.imageSize ?? 100
  return (
    <ErrorBoundary
      fallback={({ error }) => {
        captureNoncriticalError(error, { extras: props })
        return <Error />
      }}
    >
      <Suspense
        fallback={<PreviewList.Skeleton count={count} imageSize={imageSize} />}
      >
        <Content {...props} count={count} imageSize={imageSize} />
      </Suspense>
    </ErrorBoundary>
  )
}

const Content = ({
  slug,
  stringTraits = [],
  numericTraits = [],
  count,
  delay = 500,
  imageSize,
}: CollectionAssetsPreviewProps & {
  count: number
  imageSize: number
}) => {
  const [delayPassed, setDelayPassed] = useState(false)

  // Check if the delay has passed
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDelayPassed(true)
    }, delay)

    return () => {
      clearTimeout(timeout)
    }
  }, [delay])

  const t = useTranslate("components")
  const data = useLazyLoadQuery<CollectionAssetsPreviewQuery>(
    graphql`
      query CollectionAssetsPreviewQuery(
        $slug: CollectionSlug!
        $stringTraits: [TraitInputType!]
        $numericTraits: [TraitRangeType!]
        $count: Int!
      ) {
        collectionItems(
          collections: [$slug]
          stringTraits: $stringTraits
          numericTraits: $numericTraits
          first: $count
          resultType: ASSETS
        ) {
          edges {
            node {
              ... on AssetType {
                id
                name
                ...AssetMedia_asset
                ...item_url
              }
            }
          }
        }
      }
    `,
    { slug, stringTraits, numericTraits, count },
    { fetchPolicy: delayPassed ? "store-or-network" : "store-only" },
  )

  const items = getNodes(data.collectionItems)
  const itemCount = items.length

  // This next line is possible due to the fetchPolicy above
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!data.collectionItems) {
    return <PreviewList.Skeleton count={count} imageSize={imageSize} />
  }

  if (itemCount <= 0) {
    const message =
      stringTraits.length > 0 || numericTraits.length > 0
        ? t(
            "collectionAssetsPreview.noAssetsFoundWithTraits",
            "No previews available with currently selected traits.",
          )
        : t("collectionAssetsPreview.noAssetsFound", "No previews available.")

    return (
      <Flex className="p-4">
        <Text weight="semibold">{message}</Text>
      </Flex>
    )
  }

  return (
    <div
      style={{
        position: "relative",
        height: imageSize,
        width: itemCount * imageSize,
      }}
    >
      <div style={{ position: "absolute" }}>
        <PreviewList.Skeleton count={itemCount} imageSize={imageSize} />
      </div>
      <div style={{ position: "absolute" }}>
        <PreviewList count={itemCount}>
          {items.map(item => (
            <PreviewList.Item key={item.id}>
              <Link href={getItemUrl(item)}>
                <AssetMedia
                  alt={item.name ?? ""}
                  asset={item}
                  rawImage
                  size={imageSize}
                  width={imageSize}
                />
              </Link>
            </PreviewList.Item>
          ))}
        </PreviewList>
      </div>
    </div>
  )
}

const Error = () => {
  const t = useTranslate("components")
  return (
    <Flex className="p-4">
      <Text weight="semibold">
        {t(
          "collectionAssetsPreview.error",
          "There has been an issue fetching this data. Please refresh and try again.",
        )}
      </Text>
    </Flex>
  )
}
